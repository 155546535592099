import { isEmpty } from "remeda";

import type {
  AcceptMarketplaceTermsRequest,
  AcceptMarketplaceTermsResponse,
  ChangeSubscriptionRequest,
  CreateNetworkRequest,
  CreateNetworkResponse,
  CreateOrganizationDomainRequest,
  CreateOrganizationInviteRequest,
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  CreateRpcKeyRequest,
  CreateRpcKeyResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  CreateUploadUrlRequest,
  CreateUploadUrlResponse,
  DeleteOrganizationDomainRequest,
  DeleteRpcKeyRequest,
  DeleteRpcKeyResponse,
  DeleteTestnetRequest,
  DripEthRequest,
  DripEthResponse,
  EstimateDeploymentCostRequest,
  EstimateDeploymentCostResponse,
  EstimateRecurringDACostRequest,
  EstimateRecurringDACostResponse,
  GetChainIdUniqueResponse,
  GetCustomerPortalRequest,
  GetCustomerPortalResponse,
  GetIntegrationAuthEndpointRequest,
  GetIntegrationAuthEndpointResponse,
  GetIntegrationPaymentInfoRequest,
  GetIntegrationPaymentInfoResponse,
  GetIntegrationPrestartJobsRequest,
  GetIntegrationPrestartJobsResponse,
  GetNetworkComponentStatusRequest,
  GetNetworkComponentStatusResponse,
  GetNetworkCustomizationSettingsRequest,
  GetNetworkCustomizationSettingsResponse,
  GetNetworkRequest,
  GetNetworkResponse,
  GetNetworkStatusRequest,
  GetNetworkStatusResponse,
  GetPricingRequest,
  GetPricingResponse,
  GetPublishedRequest,
  GetPublishedResponse,
  GetRpcKeyEndpointsRequest,
  GetRpcKeyEndpointsResponse,
  GetUserResponse,
  InstallIntegrationRequest,
  InstallIntegrationResponse,
  IsChainIdUniqueRequest,
  IsChainIdUniqueResponse,
  ListAllEndpointsRequest,
  ListAllEndpointsResponse,
  ListIntegrationsRequest,
  ListIntegrationsResponse,
  ListNetworksRequest,
  ListNetworksResponse,
  ListOrganizationDomainsRequest,
  ListOrganizationDomainsResponse,
  ListOrganizationMembersRequest,
  ListOrganizationMembersResponse,
  ListRpcKeysRequest,
  ListRpcKeysResponse,
  NetworkGraphDataRequest,
  NetworkGraphDataResponse,
  OrganizationInviteInfoRequest,
  OrganizationInviteInfoResponse,
  PauseTestnetRequest,
  RecreateRpcKeyRequest,
  RedeemOrganizationInviteRequest,
  RedeemOrganizationInviteResponse,
  RemoveOrganizationMemberRequest,
  RemoveOrganizationMemberResponse,
  RpcKeyResponse,
  SetRpcKeyEndpointsRequest,
  SetUsageLimitsRequest,
  SetUsageLimitsResponse,
  SubmitIntegrationConfigurationRequest,
  SubmitIntegrationConfigurationResponse,
  TestnetConfigFilesResponse,
  UninstallIntegrationRequest,
  UninstallIntegrationResponse,
  UnpauseTestnetRequest,
  UpdateNetworkCustomizationSettingsRequest,
  UpdateNetworkCustomizationSettingsResponse,
  UpdateNetworkDomainsRequest,
  UpdateNetworkDomainsResponse,
  UpdateNetworkRequest,
  UpdateNetworkResponse,
  UpdateUserOrganizationRequest,
  UpdateUserOrganizationResponse,
  VerifyOrganizationDomainRequest,
  VerifyOrganizationDomainResponse,
} from "./api-types";
import { APIUtilities } from "./api-util";

class ConduitAPI extends APIUtilities {
  constructor() {
    super();
  }

  /**
   * Routes
   */

  /**
   * /v1/user
   */

  public async loadUser() {
    const resp = await this.getWithRetry<GetUserResponse>("/v1/user", {
      retries: 2,
    });

    /**
     * It seems, sometimes the /v1/user endpoint returns a 200 with an empty body or an empty user key
     */
    if (!resp || isEmpty(resp.user as unknown as Record<string, never>)) {
      throw new Error("loadUser FAILED");
    }

    return resp;
  }

  /**
   * @deprecated Unused
   */
  public updateUser(request: { onboardingFormDone: boolean }) {
    return this.postResAsText<unknown>("/v1/user/update", request);
  }

  /**
   * /v1/network
   */

  public createNetwork(request: CreateNetworkRequest) {
    return this.post<CreateNetworkResponse>("/v1/network/create", request);
  }

  public getEstimateDeploymentCost(request: EstimateDeploymentCostRequest) {
    return this.post<EstimateDeploymentCostResponse>(
      "/public/estimateDeploymentCost",
      request,
    );
  }

  public getEstimateRecurringDACost(request: EstimateRecurringDACostRequest) {
    return this.post<EstimateRecurringDACostResponse>(
      "/public/estimateRecurringDACost",
      request,
    );
  }

  public listNetworks(request: ListNetworksRequest) {
    return this.postWithRetry<ListNetworksResponse>(
      "/v1/network/list",
      request,
    );
  }

  public getNetwork(request: GetNetworkRequest) {
    return this.postWithRetry<GetNetworkResponse>("/v1/network/get", request);
  }

  public deleteTestnet(request: DeleteTestnetRequest) {
    return this.postResAsText<unknown>("/v1/network/delete", request);
  }

  public updateTestnet(request: UpdateNetworkRequest) {
    return this.post<UpdateNetworkResponse>("/v1/network/update", request);
  }

  public updateDomains(request: UpdateNetworkDomainsRequest) {
    return this.post<UpdateNetworkDomainsResponse>(
      "/v1/network/updateDomains",
      request,
    );
  }

  public getCustomizations(request: GetNetworkCustomizationSettingsRequest) {
    return this.postWithRetry<GetNetworkCustomizationSettingsResponse>(
      "/v1/network/getCustomizations",
      request,
    );
  }

  public updateCustomizations(
    request: UpdateNetworkCustomizationSettingsRequest,
  ) {
    return this.postWithRetry<UpdateNetworkCustomizationSettingsResponse>(
      "/v1/network/updateCustomizations",
      request,
    );
  }

  public createUploadUrl(request: CreateUploadUrlRequest) {
    return this.postWithRetry<CreateUploadUrlResponse>(
      "/v1/network/createUploadUrl",
      request,
    );
  }

  public getNetworkGraphData(request: NetworkGraphDataRequest) {
    return this.post<NetworkGraphDataResponse>(
      "/v1/network/getGraphData",
      request,
    );
  }

  public getChainIdUnique() {
    return this.postWithRetry<GetChainIdUniqueResponse>(
      "/public/getChainIdUnique",
      {},
    );
  }

  public isChainIdUnique(
    request: IsChainIdUniqueRequest,
    signal?: AbortSignal,
  ) {
    return this.post<IsChainIdUniqueResponse>(
      "/public/isChainIdUnique",
      request,
      signal,
    );
  }

  /**
   * @deprecated Unused
   */
  public pauseTestnet(request: PauseTestnetRequest) {
    return this.postResAsText<unknown>("/v1/network/pause", request);
  }

  public unpauseTestnet(request: UnpauseTestnetRequest) {
    return this.postResAsText<unknown>("/v1/network/unpause", request);
  }

  /**
   * @deprecated
   */
  public initiateCheckoutSessionForNetwork(request: {
    network: string;
    organization: string;
    plan: 0 | 1 | 2 | 3;
  }) {
    return this.postWithRetry<{
      url: string;
      network: string;
      organization: string;
    }>("/v1/network/createCheckoutSession", request);
  }

  public getNetworkStatus(request: GetNetworkStatusRequest) {
    return this.postWithRetry<GetNetworkStatusResponse>(
      "/v1/network/status",
      request,
    );
  }

  public getNetworkComponentStatus(request: GetNetworkComponentStatusRequest) {
    return this.postWithRetry<GetNetworkComponentStatusResponse>(
      "/v1/network/component/status",
      request,
    );
  }

  public constructNetworkComponentLogsRequests({
    network,
    name,
    container,
    organization,
  }: {
    network: string;
    name: string;
    container: string;
    organization: string;
  }) {
    const url = new URL(`/v1/network/component/logs`, this.apiHost);

    url.searchParams.append("id", network);
    url.searchParams.append("org", organization);
    url.searchParams.append("name", name);
    url.searchParams.append("container", container);

    return {
      url: url.toString(),
      options: {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      } satisfies RequestInit,
    };
  }

  public constructPrestartJobLogsRequests({
    network,
    organization,
  }: {
    network: string;
    organization: string;
  }) {
    const url = new URL(`/v1/network/prestartJobLogs`, this.apiHost);

    url.searchParams.append("id", network);
    url.searchParams.append("org", organization);

    return {
      url: url.toString(),
      options: {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      } satisfies RequestInit,
    };
  }

  /**
   * /v1/organization
   */

  public createOrganization(request: CreateOrganizationRequest) {
    return this.post<CreateOrganizationResponse>(
      "/v1/organization/create",
      request,
    );
  }

  public listOrganizationMembers(request: ListOrganizationMembersRequest) {
    return this.post<ListOrganizationMembersResponse>(
      "/v1/organization/members/list",
      request,
    );
  }

  public removeOrganizationMember(request: RemoveOrganizationMemberRequest) {
    return this.postWithRetry<RemoveOrganizationMemberResponse>(
      "/v1/organization/members/remove",
      request,
    );
  }

  public updateUserOrganizationRole(request: UpdateUserOrganizationRequest) {
    return this.post<UpdateUserOrganizationResponse>(
      "/v1/organization/members/updateRole",
      request,
    );
  }

  public getOrganizationInviteInfo(request: OrganizationInviteInfoRequest) {
    return this.post<OrganizationInviteInfoResponse>(
      "/v1/organization/invites/getInfo",
      request,
    );
  }

  public getPricing(request: GetPricingRequest) {
    return this.post<GetPricingResponse>("/v1/organization/pricing", request);
  }

  public changeSubscription(request: ChangeSubscriptionRequest) {
    return this.post<void>("/v1/organization/subscriptions/change", request);
  }

  public createSubscriptionRequest(request: CreateSubscriptionRequest) {
    return this.post<CreateSubscriptionResponse>(
      "/v1/organization/subscriptions/create",
      request,
    );
  }

  public listOrganizationDomains(request: ListOrganizationDomainsRequest) {
    return this.post<ListOrganizationDomainsResponse>(
      "/v1/organization/domains/list",
      request,
    );
  }

  public deleteOrganizationDomain(request: DeleteOrganizationDomainRequest) {
    return this.postResAsText<unknown>(
      "/v1/organization/domains/delete",
      request,
    );
  }

  public verifyOrganizationDomain(request: VerifyOrganizationDomainRequest) {
    return this.post<VerifyOrganizationDomainResponse>(
      "/v1/organization/domains/verify",
      request,
    );
  }

  public createOrganizationDomain(request: CreateOrganizationDomainRequest) {
    return this.post("/v1/organization/domains/create", request);
  }

  public createOrganizationInvites(request: CreateOrganizationInviteRequest) {
    return this.post("/v1/organization/invites/create", request);
  }

  public redeemOrganizationInvite(request: RedeemOrganizationInviteRequest) {
    return this.post<RedeemOrganizationInviteResponse>(
      "/v1/organization/invites/redeem",
      request,
    );
  }

  /**
   * @deprecated
   */
  public createCheckoutSession(request: unknown) {
    return this.postWithRetry<unknown>(
      "/v1/organization/billing/createCheckoutSession",
      request,
    );
  }

  public handleCustomerPortal(request: GetCustomerPortalRequest) {
    return this.post<GetCustomerPortalResponse>(
      "/v1/organization/billing/handleCustomerPortal",
      request,
    );
  }

  /**
   * @deprecated
   */
  public sendDemoRequest(request: unknown) {
    return this.postWithRetry<unknown>(
      "/v1/organization/billing/sendDemoRequest",
      request,
    );
  }

  /**
   * /v1/rpckey
   */

  public setUsageLimit(request: SetUsageLimitsRequest) {
    return this.post<SetUsageLimitsResponse>(
      "/v1/rpckey/setUsageLimits",
      request,
    );
  }

  public createRpcKey(request: CreateRpcKeyRequest) {
    return this.post<CreateRpcKeyResponse>("/v1/rpckey/create", request);
  }

  public setRpcKeyEndpoints(request: SetRpcKeyEndpointsRequest) {
    return this.postResAsText<unknown>("/v1/rpckey/setEndpoints", request);
  }

  public deleteRpcKey(request: DeleteRpcKeyRequest) {
    return this.post<DeleteRpcKeyResponse>("/v1/rpckey/delete", request);
  }

  public listRpcKeys(request: ListRpcKeysRequest) {
    return this.postWithRetry<ListRpcKeysResponse>("/v1/rpckey/list", request);
  }

  public getRpcKeyEndpoints(request: GetRpcKeyEndpointsRequest) {
    return this.postWithRetry<GetRpcKeyEndpointsResponse>(
      "/v1/rpckey/listEndpoints",
      request,
    );
  }

  public recreateRpcKey(request: RecreateRpcKeyRequest) {
    return this.post<RpcKeyResponse>("/v1/rpckey/recreate", request);
  }

  public listAllEndpoints(request: ListAllEndpointsRequest) {
    return this.postWithRetry<ListAllEndpointsResponse>(
      "/v1/rpckey/endpoints",
      request,
    );
  }

  /**
   * /v1/integration
   */

  public listIntegrations(request: ListIntegrationsRequest) {
    return this.post<ListIntegrationsResponse>("/v1/integration/list", request);
  }

  public getIntegrationOauthEndpoint(
    request: GetIntegrationAuthEndpointRequest,
  ) {
    return this.postWithRetry<GetIntegrationAuthEndpointResponse>(
      "/v1/integration/getAuthEndpoint",
      request,
    );
  }

  public installIntegration(request: InstallIntegrationRequest) {
    return this.post<InstallIntegrationResponse>(
      "/v1/integration/install",
      request,
    );
  }

  public uninstallIntegration(request: UninstallIntegrationRequest) {
    return this.post<UninstallIntegrationResponse>(
      "/v1/integration/uninstall",
      request,
    );
  }

  public acceptMarketplaceTerms(request: AcceptMarketplaceTermsRequest) {
    return this.post<AcceptMarketplaceTermsResponse>(
      "/v1/integration/acceptTerms",
      request,
    );
  }

  public getIntegrationPrestartJobs(
    request: GetIntegrationPrestartJobsRequest,
  ) {
    return this.post<GetIntegrationPrestartJobsResponse>(
      "/v1/integration/prestartJobs",
      request,
    );
  }

  public submitIntegrationConfiguration(
    request: SubmitIntegrationConfigurationRequest,
  ) {
    return this.post<SubmitIntegrationConfigurationResponse>(
      "/v1/integration/submitConfigurationInputs",
      request,
    );
  }

  public getIntegrationPaymentInfo(request: GetIntegrationPaymentInfoRequest) {
    return this.post<GetIntegrationPaymentInfoResponse>(
      "/v1/integration/paymentInfo",
      request,
    );
  }

  /**
   * Public Routes
   */

  public getPublishedTestnet(request: GetPublishedRequest) {
    return this.post<GetPublishedResponse>(
      "/public/network/getPublished",
      request,
    );
  }

  /**
   * @note returns bytes
   * @see https://github.com/conduitxyz/conduit/blob/main/server/cmd/api/network.go#L2199
   */
  public getTestnetBootnodes(slug: string) {
    return this.fetcher<string>({
      input: new URL(`/public/network/bootnodes/${slug}`, this.apiHost),
      init: { method: "GET" },
      readBodyAsText: true,
      retries: 0,
    });
  }

  public getTestnetStaticPeers(slug: string) {
    return this.fetcher<string>({
      input: new URL(`/public/network/staticPeers/${slug}`, this.apiHost),
      init: { method: "GET" },
      readBodyAsText: true,
      retries: 0,
    });
  }

  public getTestnetConfigFiles(slug: string) {
    return this.get<TestnetConfigFilesResponse>(
      `/public/network/files/${slug}`,
    );
  }

  public dripEth(request: DripEthRequest) {
    return this.post<DripEthResponse>("/v1/faucet/dripEth", request);
  }
}

export const conduitAPI = new ConduitAPI();
