import type { User as FirebaseUser } from "firebase/auth";
import { createContext, useContext, useState } from "react";
import { isEmpty } from "remeda";

import type { GetUserResponse, Organization } from "../apis/api-types";
import { conduitAPI } from "../apis/conduit-api";
import { CURRENT_ORGANIZATION } from "../constants/localStorage";
import useAssignContexts from "../hooks/useAssignContexts";
import { auth, logOut, useAuthState } from "../lib/firebase";
import {
  setEmail,
  setName,
  setUser as setUserAuthStore,
  setUserObject as setUserObjectAuthStore,
} from "../stores/AuthStore";

type EmptyObject = Record<string, never>;

type TAuthContext = {
  user: FirebaseUser | EmptyObject | null;
  userObject: GetUserResponse | EmptyObject;

  organization: Organization | EmptyObject;
  organizations: Organization[];

  setCurrentOrganization(org: Organization | EmptyObject): void;
  addNewOrganization(org: Organization): void;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const assignContexts = useAssignContexts();

  const [user, setUser] = useState<FirebaseUser | EmptyObject | null>({});
  const [userObject, setUserObject] = useState<GetUserResponse | EmptyObject>(
    {},
  );

  const [organization, setOrganization] = useState<Organization | EmptyObject>(
    {},
  );
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  function setCurrentOrganization(org: Organization | EmptyObject) {
    try {
      if (isEmpty(org as Record<string, never>)) {
        window.localStorage.removeItem(CURRENT_ORGANIZATION);
      } else {
        window.localStorage.setItem(CURRENT_ORGANIZATION, org.organization);
      }
    } catch (error) {
      console.error(error);
    }

    setOrganization(org);
  }

  function addNewOrganization(org: Organization) {
    if (
      organizations.filter((o) => org.organization === o.organization)
        .length === 0
    ) {
      setOrganizations((old) => [...old, org]);
    }
  }

  function clearUserData() {
    setUserObject({});

    setUserObjectAuthStore({});
    setName("");
    setEmail("");

    setCurrentOrganization({});
    setOrganizations([]);
  }

  async function loadUserFromAPI() {
    try {
      const userJson = await conduitAPI.loadUser();

      assignContexts(userJson);

      setUserObject(userJson);

      setUserObjectAuthStore(userJson);
      setName(
        userJson.user.name.trim() === ""
          ? userJson.user.email
          : userJson.user.name,
      );
      setEmail(userJson.user.email);

      if (userJson.organizations.length > 0) {
        const storedOrgID = window.localStorage.getItem(CURRENT_ORGANIZATION);

        let setOrg = false;

        if (storedOrgID && storedOrgID !== undefined) {
          userJson.organizations.forEach((org) => {
            if (org.organization === storedOrgID) {
              setCurrentOrganization(org);
              setOrg = true;
            }
          });
        }

        if (!setOrg) {
          setCurrentOrganization(userJson.organizations[0]);
        }
      }

      setOrganizations(userJson.organizations);
    } catch (err) {
      console.error(err);

      await logOut();
    }
  }

  useAuthState(auth, {
    async onUserChanged(currentUser) {
      setUser(currentUser);
      setUserAuthStore(currentUser);

      if (currentUser) {
        await loadUserFromAPI();
      } else {
        clearUserData();
      }
    },
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        userObject,

        organization,
        organizations,
        setCurrentOrganization,
        addNewOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useUserAuth() {
  const context = useContext<TAuthContext>(AuthContext);

  if (!context) {
    throw new Error("useUserAuth has to be used within <AuthContextProvider>");
  }

  return context;
}
